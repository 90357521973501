<template>
<div class="vx-row">
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card title="Filter">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(GenerateReport)">
                    <div class="filter-container">
                        <h6 class="font-bold mb-4">Case Officer</h6>
                        <div>
                            <vs-select class="sm:w-full lg:w-1/1 w-full" name="title" v-model="req.caseOfficerId" placeholder="Select">
                                <vs-select-item :key="index" :value="item.id" :text="item.displayName" v-for="(item, index) in caseOfficers" />
                            </vs-select>
                        </div>
                        <vs-divider />
                        <h6 class="font-bold mb-4">Select Department</h6>
                        <div>
                            <vs-select class="sm:w-full lg:w-1/1 w-full" name="title" v-model="req.departmentId" placeholder="Select">
                                <vs-select-item :key="index" :value="item.id" :text="item.clientDepartment" v-for="(item, index) in departments" />
                            </vs-select>
                        </div>
                        <vs-divider />
                        <h6 class="font-bold mb-3">Start Date</h6>
                        <div>
                            <ValidationProvider name="StartDate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker class="sm:w-full lg:w-1/1 w-full" width="150px" v-model="req.StartDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <vs-divider />
                        <h6 class="font-bold mb-4">End Date</h6>
                        <div>
                            <ValidationProvider name="StartDate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker class="sm:w-full lg:w-1/1 w-full" v-model="req.EndDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <vs-divider />
                        <div class="flex justify-center">
                            <vs-button class="w-full" @click="GenerateReport">Apply</vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vx-card>
    </div>
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <vx-card class="item-details mb-base welcomeCard" title="Non-Completed Jobs">
            <template slot="actions">
                <!-- <vs-button color="danger" @click="exportToPDF" :disabled="isExport">Report</vs-button>
                <vs-button class="ml-2" color="success" @click="exportToExcel" :disabled="isExport">Export</vs-button> -->
            </template>
            <vs-table stripe :data="requestReceivedData">
                <template #thead>
                    <vs-th>File Number</vs-th>
                    <vs-th>Name</vs-th>
                    <vs-th>Level</vs-th>
                    <vs-th>Case Officer</vs-th>
                    <vs-th>Pack Received</vs-th>
                    <vs-th>Required Return Date</vs-th>
                    <vs-th>Comment</vs-th>
                </template>
                <template>
                    <vs-tr :key="i" v-for="(tr, i) in requestReceivedData" :data="tr">
                        <vs-td>{{ tr.fileNumber}}</vs-td>
                        <vs-td>{{ tr.displayName}}</vs-td>
                        <vs-td>
                            {{ tr.level }}
                        </vs-td>
                        <vs-td>
                            {{ tr.caseOfficer }}
                        </vs-td>
                        <vs-td>
                            {{ tr.packReceived  | formatLongDate}}
                        </vs-td>
                        <vs-td>
                            {{ tr.requiredReturnDate  | formatLongDate}}
                        </vs-td>
                        <vs-td>

                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    reportsData
} from "../../store/api/report";
import {
    departmentData
} from "../../store/api/department";
import {
    userData
} from "../../store/api/user";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import {
    customMessages
} from "../../filters/validationmessage";

export default {
    data() {
        return {
            departments: [],
            requestReceivedData: [],
            req: {

            },
            caseOfficers: [],
            isExport: true,
            customMessages

        }
    },
    components: {
        DatePicker,
    },
    async created() {
        await this.loadDepartments();
        await this.loadCaseOfficers();
    },
    methods: {
        async loadDepartments() {
            this.departments = [];
            this.departments = await departmentData.getAllDepartment();
        },
        async loadCaseOfficers() {
            this.caseOfficers = [];
            this.caseOfficers = await userData.getCaseOfficer();
        },
        async GenerateReport() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            this.requestReceivedData = [];
            this.requestReceivedData = await reportsData.NonCompletedJobs(this.req);
            if (!this.requestReceivedData.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }
        },
        async exportToExcel() {
            let data = await reportsData.ExportToExcelCompletedJobs(this.req);
            //window.open(data.url, '_blank').focus();
        },
        async exportToPDF() {
            let data = await reportsData.ExportToPDFCompletedJobs(this.req);
            //window.open(data.url, '_blank').focus();
        },
    }
}
</script>
